import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { officeSpaceReducer } from './reducers/officeSpace';
import { meetingRoomsReducer } from './reducers/meetingRooms';
import { allBookingsReducer } from './reducers/allBookings';
import { userReducer } from './reducers/user';
import { rootApi } from '../api/rootApi';

const combinedReducer = combineReducers({
  officeSpaceReducer,
  meetingRoomsReducer,
  allBookingsReducer,
  userReducer,
  [rootApi.reducerPath]: rootApi.reducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rootApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
