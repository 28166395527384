import { createApi } from '@reduxjs/toolkit/query/react';
import { Dayjs } from 'dayjs';
import { OFFICE_SPACES_ROUTES, USERS_ROUTES, STORE_TAGS, REPEAT_VALUE } from '../constants';
import { MyBooking /* setAllBookings */ } from '../store/reducers/allBookings';
import { setAllMeetingRoomUsers } from '../store/reducers/meetingRooms';
import { setBookedSpaces } from '../store/reducers/officeSpace';
import { setUser } from '../store/reducers/user';
import { baseQuery } from './interceptor';

export interface GetUserResponse {
  id: string;
  name: string;
  surname: string;
  telegramUsername: string;
  telegramUserId: number;
  createdAt: string;
  updatedAt: string;
}

interface ChangeUserBody {
  id: string;
  name: string;
  surname: string;
  queryId: string;
}

interface CreateBookingPeriodBody {
  queryId: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  chatId?: string;
  telegramUserId: number;
}

export interface GetMeetingRoomUsersResponse {
  id: string;
  name: string;
  surname: string;
  telegramUserName: string;
  telegramUserId: number;
  userMeetingRoomInfo: {
    id: string;
    spaceName: string;
    roomId: string;
    date: string;
    endTime: string;
    startTime: string;
    isRepeated: boolean;
    repeatValue: REPEAT_VALUE | null;
    repeatEndDate: string | null;
    isRepeatAlways: boolean;
  };
}

interface BookMeetingRoomBody {
  meetingRoomId: string;
  telegramUserId: number;
  date: string;
  startTime: string;
  endTime: string;
  queryId: string;
  spaceName: string;
  allDay: boolean;
  isRepeated?: boolean;
  repeatValue?: REPEAT_VALUE | null;
  repeatEndDate?: string | null;
  isRepeatAlways?: boolean;
}

export interface GetBookedOfficeSpacesResponse {
  id: string;
  name: string;
  startDate: string;
  endDate: string | null;
  userSpaceId: string;
  isRepeatAlways: boolean;
  spaceUser: {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number;
  };
}

interface BookOfficeSpaceBody {
  queryId: string;
  spaceName: string;
  telegramUserId: number;
  startDate: string;
  endDate?: string | null;
}

interface RegisterUserBody {
  telegramUserName: string;
  telegramUserId: number;
  name: string;
  surname: string;
  queryId: string;
}

interface EditOfficeSpaceBody {
  queryId: string;
  spaceName: string;
  editSpaceId: string;
  startDate: string;
  endDate?: string | null;
}

interface EditMeetingRoomBooking {
  id: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  isRepeated?: boolean;
  repeatValue?: REPEAT_VALUE | null;
  repeatEndDate?: string | null;
  isRepeatAlways?: boolean;
}

interface GetAllBookingsResponse {
  allBookings: MyBooking[];
  expiredAllBookings: MyBooking[];
  officeSpaceBookings: MyBooking[];
  expiredOfficeSpaceBookings: MyBooking[];
  meetingRoomBookings: MyBooking[];
  expiredMeetingRoomBookings: MyBooking[];
  allBookingsCount: number;
  officeSpaceBookingsCount: number;
  meetingRoomBookingsCount: number;
  expiredAllBookingsCount: number;
  expiredOfficeSpaceBookingsCount: number;
  expiredMeetingRoomBookingsCount: number;
}

export const rootApi = createApi({
  baseQuery,
  tagTypes: Object.values(STORE_TAGS),
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, { telegramUserId: number }>({
      query: ({ telegramUserId }) => ({
        url: USERS_ROUTES.GET_USER_ROUTE,
        method: 'GET',
        params: { telegramUserId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setUser({ user: data }));
        }
      },

      providesTags: [STORE_TAGS.USER],
    }),

    changeUser: builder.mutation<{ message: string }, ChangeUserBody>({
      query: ({ id, name, surname, queryId }) => ({
        url: USERS_ROUTES.CHANGE_USER_ROUTE,
        method: 'PATCH',
        body: { id, name, surname, queryId },
      }),
      invalidatesTags: [STORE_TAGS.USER],
    }),

    createBookingPeriod: builder.mutation<{ message: string }, CreateBookingPeriodBody>({
      query: ({ startDate, endDate, queryId, chatId, telegramUserId }) => ({
        url: OFFICE_SPACES_ROUTES.CREATE_BOOKING_PERIOD_ROUTE,
        method: 'POST',
        body: { startDate, endDate, queryId, chatId, telegramUserId },
      }),
    }),

    getMeetingRoomUsers: builder.query<
      GetMeetingRoomUsersResponse[],
      { date: string; meetingRoomId: string; endDate?: string }
    >({
      query: ({ date, meetingRoomId, endDate }) => ({
        url: OFFICE_SPACES_ROUTES.GET_MEETING_ROOM_USERS_ROUTE,
        method: 'GET',
        params: { date, meetingRoomId, endDate },
      }),

      /*    async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setMeetingRoomUsers({ newMeetingRoomUsers: data }));
        }
      }, */
      providesTags: [STORE_TAGS.MEETING_ROOM],
    }),

    bookMeetingRoom: builder.mutation<{ message: string }, BookMeetingRoomBody>({
      query: ({
        meetingRoomId,
        telegramUserId,
        date,
        startTime,
        endTime,
        queryId,
        spaceName,
        allDay,
        isRepeated,
        repeatValue,
        repeatEndDate,
        isRepeatAlways,
      }) => ({
        url: OFFICE_SPACES_ROUTES.BOOK_MEETING_ROOM_ROUTE,
        method: 'POST',
        body: {
          meetingRoomId,
          telegramUserId,
          date,
          startTime,
          endTime,
          queryId,
          spaceName,
          allDay,
          isRepeated,
          repeatValue,
          repeatEndDate,
          isRepeatAlways,
        },
      }),
      invalidatesTags: [STORE_TAGS.MEETING_ROOM, STORE_TAGS.ALL_BOOKINGS],
    }),

    getBookedOfficeSpaces: builder.query<
      GetBookedOfficeSpacesResponse[],
      { startDate: string; endDate?: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: OFFICE_SPACES_ROUTES.GET_BOOKED_OFFICE_SPACES_ROUTE,
        method: 'GET',
        params: { startDate, endDate },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setBookedSpaces({ newBookedSpaces: data }));
        }
      },
      providesTags: [STORE_TAGS.OFFICE_SPACES],
    }),

    bookOfficeSpace: builder.mutation<{ message: string }, BookOfficeSpaceBody>({
      query: ({ queryId, spaceName, telegramUserId, startDate, endDate }) => ({
        url: OFFICE_SPACES_ROUTES.BOOK_OFFICE_SPACE_ROUTE,
        method: 'POST',
        body: { queryId, spaceName, telegramUserId, startDate, endDate },
      }),
      invalidatesTags: [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS],
    }),

    removeOfficeSpace: builder.mutation<{ message: string }, { userSpaceId: string }>({
      query: ({ userSpaceId }) => ({
        url: OFFICE_SPACES_ROUTES.REMOVE_OFFICE_SPACE_ROUTE,
        method: 'DELETE',
        body: { userSpaceId },
      }),
      invalidatesTags: [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS],
    }),

    registerUser: builder.mutation<{ message: string }, RegisterUserBody>({
      query: ({ telegramUserName, telegramUserId, name, surname, queryId }) => ({
        url: USERS_ROUTES.REGISTER_USER_ROUTE,
        method: 'POST',
        body: { telegramUserName, telegramUserId, name, surname, queryId },
      }),
      invalidatesTags: [STORE_TAGS.USER],
    }),

    editOfficeSpace: builder.mutation<{ message: string }, EditOfficeSpaceBody>({
      query: ({ queryId, spaceName, editSpaceId, startDate, endDate }) => ({
        url: OFFICE_SPACES_ROUTES.EDIT_OFFICE_SPACE_ROUTE,
        method: 'PATCH',
        body: { queryId, spaceName, editSpaceId, startDate, endDate },
      }),
      invalidatesTags: [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS],
    }),

    getAllMeetingRoomsUsers: builder.query<
      GetMeetingRoomUsersResponse[],
      { date: string; endDate?: string; repeatValue?: string }
    >({
      query: ({ date, endDate, repeatValue }) => ({
        url: OFFICE_SPACES_ROUTES.GET_ALL_MEETING_ROOMS_USERS_ROUTE,
        method: 'GET',
        params: { date, endDate, repeatValue },
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: data }));
        }
      },
      providesTags: [STORE_TAGS.ALL_MEETING_ROOMS],
    }),

    getParticularBooking: builder.query<GetBookedOfficeSpacesResponse, { id: string }>({
      query: ({ id }) => ({
        url: OFFICE_SPACES_ROUTES.GET_PARTICULAR_BOOKING_ROUTE,
        method: 'GET',
        params: { id },
      }),
    }),

    editMeetingRoomBooking: builder.mutation<{ message: string }, EditMeetingRoomBooking>({
      query: ({
        id,
        startTime,
        endTime,
        allDay,
        isRepeated,
        repeatValue,
        repeatEndDate,
        isRepeatAlways,
      }) => ({
        url: OFFICE_SPACES_ROUTES.EDIT_MEETING_ROOM_BOOKING_ROUTE,
        method: 'PATCH',
        body: {
          id,
          startTime,
          endTime,
          allDay,
          isRepeated,
          repeatValue,
          repeatEndDate,
          isRepeatAlways,
        },
      }),
      invalidatesTags: [
        STORE_TAGS.MEETING_ROOM,
        STORE_TAGS.ALL_MEETING_ROOMS,
        STORE_TAGS.ALL_BOOKINGS,
      ],
    }),

    removeMeetingRoomBooking: builder.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: OFFICE_SPACES_ROUTES.REMOVE_MEETING_ROOM_BOOKING_ROUTE,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: [
        STORE_TAGS.MEETING_ROOM,
        STORE_TAGS.ALL_MEETING_ROOMS,
        STORE_TAGS.ALL_BOOKINGS,
      ],
    }),

    getParticularMeetingRoomBooking: builder.query<GetMeetingRoomUsersResponse, { id: string }>({
      query: ({ id }) => ({
        url: OFFICE_SPACES_ROUTES.GET_PARTICULAR_MEETING_ROOM_BOOKING_ROUTE,
        method: 'GET',
        params: { id },
      }),
    }),

    getAllBookings: builder.query<
      GetAllBookingsResponse,
      { telegramUserId: number; limit: number; offset: number; historyOffset: number }
    >({
      query: ({ telegramUserId, limit, offset, historyOffset }) => ({
        url: OFFICE_SPACES_ROUTES.GET_ALL_BOOKINGS_ROUTE,
        method: 'GET',
        params: { telegramUserId, limit, offset, historyOffset },
      }),

      /*  async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data) {
          dispatch(
            setAllBookings({
              allBookings: data.allBookings,
              expiredAllBookings: data.expiredAllBookings,
              officeSpaceBookings: data.officeSpaceBookings,
              expiredOfficeSpaceBookings: data.expiredOfficeSpaceBookings,
              meetingRoomBookings: data.meetingRoomBookings,
              expiredMeetingRoomBookings: data.expiredMeetingRoomBookings,
            }),
          );
        }
      }, */
      providesTags: [STORE_TAGS.ALL_BOOKINGS],
    }),

    removeAllBookings: builder.mutation<
      { message: string },
      {
        bookingIds: string[];
        removeAll?: boolean;
        isHistory?: boolean;
        isMeetingRoom?: boolean;
        isOfficeSpace?: boolean;
      }
    >({
      query: ({ bookingIds, removeAll, isHistory, isMeetingRoom, isOfficeSpace }) => ({
        url: OFFICE_SPACES_ROUTES.REMOVE_ALL_BOOKINGS_ROUTE,
        method: 'DELETE',
        body: { bookingIds, removeAll, isHistory, isMeetingRoom, isOfficeSpace },
      }),
      invalidatesTags: [
        STORE_TAGS.MEETING_ROOM,
        STORE_TAGS.ALL_MEETING_ROOMS,
        STORE_TAGS.ALL_BOOKINGS,
      ],
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useChangeUserMutation,
  useCreateBookingPeriodMutation,
  useLazyGetMeetingRoomUsersQuery,
  useBookMeetingRoomMutation,
  useLazyGetBookedOfficeSpacesQuery,
  useBookOfficeSpaceMutation,
  useRemoveOfficeSpaceMutation,
  useRegisterUserMutation,
  useEditOfficeSpaceMutation,
  useLazyGetAllMeetingRoomsUsersQuery,
  useLazyGetParticularBookingQuery,
  useEditMeetingRoomBookingMutation,
  useRemoveMeetingRoomBookingMutation,
  useLazyGetParticularMeetingRoomBookingQuery,
  useLazyGetAllBookingsQuery,
  useRemoveAllBookingsMutation,
} = rootApi;
