/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import RepeatIcon from '../../../assets/repeatIcon';
import { generateWeekViewCoordinates } from '../../../utils/generateWeekViewCoordinates';
import { MeetingRoomUser, setEditedUserMeetingRoom } from '../../../store/reducers/meetingRooms';
import { useTelegram } from '../../../hooks/useTelegram';
import { APP_ROUTES, MAX_TIME, MIN_TIME, REPEAT_VALUE } from '../../../constants';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';

import styles from './styles';
import { FAKE_ID } from '../../layouts/meetingRoomTimetable';

const MINUTES_IN_HOUR = 60;

interface BookedTimeCellProps {
  chosenDateState?: string;
  meetingRoomUser: MeetingRoomUser;
  allMeetingRooms?: boolean;
  meetingRoomName: string;
}

function BookedTimeCell({
  meetingRoomUser,
  allMeetingRooms,
  meetingRoomName,
  chosenDateState,
}: BookedTimeCellProps) {
  const { colorScheme, tgUserId } = useTelegram();

  const { scrollNavigate } = useEnhancedNavigate();

  const dispatch = useDispatch();

  const date = dayjs(`${meetingRoomUser.userMeetingRoomInfo.date}`).format('YYYY-MM-DD');

  const startTimeValue = dayjs(`${date} ${meetingRoomUser.userMeetingRoomInfo.startTime}`);

  const startTimeHour = startTimeValue.get('hour');

  const startTimeMinutes = startTimeValue.get('minutes');

  const endTimeValue = dayjs(`${date} ${meetingRoomUser.userMeetingRoomInfo.endTime}`);

  const endTimeHour = endTimeValue.get('hour');

  const endTimeMinutes = endTimeValue.get('minutes');

  const startTimeString = startTimeValue.format('HH:mm a');

  const endTimeString = endTimeValue.format('HH:mm a');

  const isMoreThanHour = endTimeValue.diff(startTimeValue, 'minutes') > MINUTES_IN_HOUR;

  const isOneHour = endTimeValue.diff(startTimeValue, 'minutes') === MINUTES_IN_HOUR;

  const isMyBook = meetingRoomUser.telegramUserId === tgUserId;

  const first = startTimeHour === MIN_TIME && startTimeMinutes === 0;

  const last = endTimeHour === MAX_TIME && endTimeMinutes === 0;

  const isTimeClickable = meetingRoomUser.telegramUserId === tgUserId;

  const bookedMeetingRoomName = meetingRoomUser.userMeetingRoomInfo.spaceName;

  const onBookedTimeClickHandler = () => {
    if (meetingRoomUser.id !== FAKE_ID) {
      dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: meetingRoomUser }));
    }

    const { startTime, endTime } = meetingRoomUser.userMeetingRoomInfo;

    scrollNavigate({
      top: 0,
      left: 0,
      path: `${
        APP_ROUTES.BOOK_MEETING_ROOM
      }?bookingStartTime=${startTime}&bookingEndTime=${endTime}&meetingRoomId=${
        meetingRoomUser.userMeetingRoomInfo.roomId
      }&bookingDate=${
        chosenDateState || meetingRoomUser.userMeetingRoomInfo.date
      }&meetingRoomName=Meeting Room ${meetingRoomName.split('')[2]}&editedMeetingRoomBookingId=${
        meetingRoomUser.userMeetingRoomInfo.id
      }${allMeetingRooms ? '&isAllMeetingRooms=true' : ''}${
        meetingRoomUser.id === FAKE_ID ? '&isAllDay=true' : ''
      }`,
      replace: true,
      behavior: 'smooth',
    });
  };

  let repeatValue = '';

  if (meetingRoomUser.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.DAY) {
    repeatValue = 'Daily';
  }

  if (meetingRoomUser.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.WEEK) {
    repeatValue = 'Weekly';
  }

  if (meetingRoomUser.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.MONTH) {
    repeatValue = 'Monthly';
  }

  return isTimeClickable ? (
    <div
      style={generateWeekViewCoordinates(
        meetingRoomUser,
        startTimeValue,
        allMeetingRooms,
        bookedMeetingRoomName,
      )}
      css={styles.bookedRowStyles({
        isMoreThanHour,
        colorScheme,
        isOneHour,
        isMyBook,
        first,
        last,
        allMeetingRooms,
      })}
      onClick={onBookedTimeClickHandler}>
      <div css={styles.bookInfoWrapperStyles({ isOneHour, isMoreThanHour, allMeetingRooms })}>
        <p
          css={styles.nameTextStyles({
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          {meetingRoomUser.name} {meetingRoomUser.surname}
        </p>
        <p
          css={styles.bookedTimeWrapper({
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          {startTimeString} - {endTimeString}
        </p>
      </div>
      {!!repeatValue && (
        <div
          css={styles.repeatInfoWrapperStyles({
            isOneHour,
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          <RepeatIcon
            color={colorScheme === 'dark' ? '#FFFFFF' : '#FFFFFF'}
            repeatIconStyles={styles.repeatIconsStyles({
              colorScheme,
              isMoreThanHour,
              allMeetingRooms,
              bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
            })}
          />
          <p
            css={styles.repeatValueTextStyles({
              isMoreThanHour,
              allMeetingRooms,
              bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
            })}>
            {repeatValue}
          </p>
        </div>
      )}
    </div>
  ) : (
    <div
      style={generateWeekViewCoordinates(
        meetingRoomUser,
        startTimeValue,
        allMeetingRooms,
        bookedMeetingRoomName,
      )}
      css={styles.bookedRowStyles({
        isMoreThanHour,
        colorScheme,
        isOneHour,
        isMyBook,
        first,
        last,
        allMeetingRooms,
      })}>
      <div css={styles.bookInfoWrapperStyles({ isOneHour, isMoreThanHour, allMeetingRooms })}>
        <p
          css={styles.nameTextStyles({
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          {meetingRoomUser.name} {meetingRoomUser.surname}
        </p>
        <p
          css={styles.bookedTimeWrapper({
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          {startTimeString} - {endTimeString}
        </p>
      </div>
      {!!repeatValue && (
        <div
          css={styles.repeatInfoWrapperStyles({
            isOneHour,
            isMoreThanHour,
            allMeetingRooms,
            bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
          })}>
          <RepeatIcon
            color={colorScheme === 'dark' ? '#FFFFFF' : '#FFFFFF'}
            repeatIconStyles={styles.repeatIconsStyles({
              colorScheme,
              isMoreThanHour,
              allMeetingRooms,
              bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
            })}
          />
          <p
            css={styles.repeatValueTextStyles({
              isMoreThanHour,
              allMeetingRooms,
              bookingMinutes: endTimeValue.diff(startTimeValue, 'minutes'),
            })}>
            {repeatValue}
          </p>
        </div>
      )}
    </div>
  );
}

export default BookedTimeCell;
