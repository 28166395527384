import { useEffect } from 'react';
import { useTelegram } from './hooks/useTelegram';
import { Router } from './routes';
import { useLazyGetUserQuery } from './api/rootApi';

import './index.css';

function App() {
  const { tg, tgUserId } = useTelegram();

  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    tg.ready();
    tg.expand();
    if (tgUserId) {
      getUser({ telegramUserId: tgUserId });
    }
  }, []);

  return (
    <div className="mainLayout">
      <Router />
    </div>
  );
}

export default App;
