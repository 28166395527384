import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  name: string;
  surname: string;
  telegramUsername: string;
  telegramUserId: number;
  createdAt: string;
  updatedAt: string;
}

interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

interface SetUserPayload {
  user: User;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: { user } }: PayloadAction<SetUserPayload>) => {
      state.user = user;
    },
  },
});

export const {
  reducer: userReducer,
  actions: { setUser },
} = userSlice;
