/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface RemoveRepeatBookingModalProps {
  open: boolean;
  onClose: () => void;
  onDeleteWorkPlaceHandler: () => void;
}

export function RemoveRepeatBookingModal({
  open,
  onClose,
  onDeleteWorkPlaceHandler,
}: RemoveRepeatBookingModalProps): ReactElement {
  const { colorScheme } = useTelegram();

  return (
    <Dialog onClose={onClose} open={open} css={styles.dialogStyles({ colorScheme })}>
      <div css={styles.modalWrapperStyles}>
        <div css={styles.headerWrapperStyles}>
          <div css={styles.closeBtnWrapperStyles}>
            <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div>
          <p css={styles.modalInfoTextStyles({ colorScheme })}>
            This is a recurring event. All subsequent bookings will also be deleted
          </p>
        </div>
        <div css={styles.btnWrapperStyles}>
          <div css={styles.repeatedBtnWrapperStyles}>
            <button
              type="button"
              css={styles.deleteBtnStyles({ colorScheme })}
              onClick={onDeleteWorkPlaceHandler}>
              Delete
            </button>
            <button type="button" css={styles.cancelBtnStyles} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
