/** @jsxImportSource @emotion/react */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OfficeSpace from '../space';
import { setOfficeSpace, setEditedUserSpace } from '../../../../store/reducers/officeSpace';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { User } from '../../../../store/reducers/user';

import styles from './styles';

interface OfficeBottomPartPartProps {
  chosenMeetingRoomDate?: string;
  isMeetingRoom: boolean;
  editWorkPlaceActive: boolean;
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setEditWorkPlaceActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMyBooking: React.Dispatch<React.SetStateAction<boolean>>;
  startDateState?: string;
  endDateState?: string;
  userInfo: User | null;
  setStartDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEndDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOtherUsersHaveBookingsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSpaceIdState: React.Dispatch<React.SetStateAction<string | null>>;
  setIsEditClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

function OfficeBottomPart({
  chosenMeetingRoomDate,
  isMeetingRoom,
  editWorkPlaceActive,
  setModalActive,
  setEditWorkPlaceActive,
  setIsMyBooking,
  startDateState,
  endDateState,
  userInfo,
  setStartDateState,
  setEndDateState,
  setOtherUsersHaveBookingsModalActive,
  setSpaceIdState,
  setIsEditClosed,
}: OfficeBottomPartPartProps) {
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useSearchParams();

  const editedSpace = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace);

  const onCloseBtnHandler = () => {
    if (editedSpace) {
      queryParams.delete('spaceId');

      queryParams.delete('spaceName');

      queryParams.set('startDate', editedSpace.startDate);

      if (editedSpace.endDate) {
        queryParams.set('endDate', editedSpace.endDate);
      } else {
        queryParams.delete('endDate');
      }

      queryParams.set('isClosed', 'true');

      queryParams.delete('periodActive');

      setQueryParams(queryParams);

      setIsEditClosed(true);

      setSpaceIdState(null);

      setStartDateState(editedSpace.startDate);

      setEndDateState(editedSpace.endDate || undefined);

      dispatch(setEditedUserSpace({ newEditedUserSpace: null }));

      dispatch(setOfficeSpace({ newSpace: '' }));

      setIsMyBooking(false);

      setEditWorkPlaceActive(false);
    }
  };

  return (
    <div css={styles.wrapperStyles}>
      {editWorkPlaceActive && (
        <IconButton css={styles.closeBtnStyles} onClick={onCloseBtnHandler}>
          <CloseIcon />
        </IconButton>
      )}
      <div css={styles.firstColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="37"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="38"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="39"
            isBlocked
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="36"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="35"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="34"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
      <div css={styles.secondColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="30"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="31"
            isBlocked
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="32"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="33"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="29"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="28"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="27"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="26"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
      <div css={styles.thirdColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="22"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="23"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="24"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="25"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="21"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="20"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="19"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="18"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
      <div css={styles.fourthColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="15"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <div css={styles.emptyRowStyles} />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="16"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="17"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="14"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="13"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="12"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="11"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
      <div css={styles.fifthColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="8"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="9"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="10"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="5"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            isBlocked
            value="6"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="7"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
      <div css={styles.sixthColumnWrapperStyles}>
        <div css={styles.spaceLeftColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="3"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            value="4"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
        <div css={styles.spaceRightColumnStyles}>
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            isBlocked
            value="2"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
          <OfficeSpace
            setModalActive={setModalActive}
            isMeetingRoom={isMeetingRoom}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsMyBooking={setIsMyBooking}
            isBlocked
            value="1"
            userInfo={userInfo}
            startDateState={startDateState}
            endDateState={endDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
          />
        </div>
      </div>
    </div>
  );
}

export default OfficeBottomPart;
