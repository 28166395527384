/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useRemoveMeetingRoomBookingMutation } from '../../../../api/rootApi';

import styles from './styles';

interface EditButtonsProps {
  endTimeValue: Dayjs | null | undefined;
  startTimeValue: Dayjs | null | undefined;
  setEditModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  editModeActive: boolean;
  setDeleteModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  onEditHandler: () => void;
  isEditMeetingRoomBookingLoading: boolean;
  startTimeError: string;
  endTimeError: string;
  isRepeated: boolean;
}

function EditButtons({
  endTimeValue,
  startTimeValue,
  setEditModeActive,
  editModeActive,
  setDeleteModalActive,
  onEditHandler,
  isEditMeetingRoomBookingLoading,
  startTimeError,
  endTimeError,
  isRepeated,
}: EditButtonsProps) {
  const [editBtnTitle, setEditBtnTitle] = useState('Edit');

  const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

  const editedMeetingRoomBooking = useAppSelector(
    (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
  );

  useEffect(() => {
    if (editModeActive && !!editedMeetingRoomBooking) {
      setEditBtnTitle('Save');
    }
  }, [editModeActive, editedMeetingRoomBooking]);

  const { colorScheme } = useTelegram();

  const onEditBtnClickHandler = async () => {
    if (editBtnTitle === 'Save') {
      onEditHandler();
    }
    setEditModeActive(true);
  };

  const onDeleteBtnHandler = async () => {
    if (isRepeated) {
      setDeleteModalActive(true);
    } else if (editedMeetingRoomBooking) {
      const result = await removeMeetingRoomBooking({
        id: editedMeetingRoomBooking.userMeetingRoomInfo.id,
      }).unwrap();

      if (result) {
        setDeleteModalActive(true);
      }
    }
  };

  const editBtnTitleText = !isEditMeetingRoomBookingLoading ? editBtnTitle : '';

  return (
    <div css={styles.btnWrapperStyles}>
      <button
        disabled={!!startTimeError || !!endTimeError || isEditMeetingRoomBookingLoading}
        type="button"
        css={styles.editBtnStyles}
        onClick={onEditBtnClickHandler}>
        {isEditMeetingRoomBookingLoading ? (
          <CircularProgress sx={{ color: 'white' }} size={20} />
        ) : (
          editBtnTitleText
        )}
      </button>
      <button
        type="button"
        css={styles.cancelBtnStyles({ colorScheme })}
        onClick={onDeleteBtnHandler}>
        Delete
      </button>
    </div>
  );
}

export default EditButtons;
