/* eslint-disable @typescript-eslint/no-magic-numbers */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const bookedRowStyles = ({
  isMoreThanHour,
  colorScheme,
  isOneHour,
  isMyBook,
  first,
  last,
  allMeetingRooms,
}: {
  isMoreThanHour: boolean;
  colorScheme: string;
  isOneHour: boolean;
  isMyBook: boolean;
  first: boolean;
  last: boolean;
  allMeetingRooms?: boolean;
}) => {
  let paddingTop;
  let justifyContent;
  let alignItems;
  let bgColor;
  let borderColor;
  let color;
  let paddingLeft;
  let borderRadius;
  let hoverObj;
  const fontSize = allMeetingRooms ? ['3px', '8px', '8px', '8px'] : '8px';
  const lineHeight = allMeetingRooms ? ['5px', '10px', '10px', '10px'] : '10px';

  if (isMoreThanHour) {
    paddingTop = '20px';
    paddingLeft = allMeetingRooms ? '5px' : '10px';
  } else {
    paddingTop = '0px';
    paddingLeft = allMeetingRooms ? '5px' : '10px';
  }

  if (isOneHour) {
    paddingTop = '0px';
  }

  if (isMyBook) {
    bgColor = '#3366FF';
    borderColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
    color = '#FFFFFF';
    hoverObj = {
      transition: 'all 0.3s ease-out',
      background: '#5f87ff',
    };
  } else if (colorScheme === 'dark') {
    bgColor = '#27292D';
    borderColor = '#383B40';
    color = '#FFFFFF';
  } else {
    color = '#000000';
    bgColor = '#D9D9D9';
    borderColor = '#C4C4C4';
  }

  if (first) {
    borderRadius = '0px 5px 0px 0px';
  }
  if (last) {
    borderRadius = '0px 0px 5px 0px';
  }

  if (!isMyBook) {
    hoverObj = {};
  }

  return css(
    mq({
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': hoverObj,
      },
      position: 'absolute',
      backgroundColor: bgColor,
      color,
      paddingLeft,
      paddingTop,
      paddingRight: '5px',
      zIndex: 1,
      cursor: isMyBook ? 'pointer' : 'default',
      display: 'flex',
      alignItems,
      flexDirection: 'row',
      justifyContent,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize,
      lineHeight,
      border: `0.5px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      borderTop: `1px solid ${borderColor}`,
      borderRadius,
      opacity: '1',
      transition: 'all 0.3s ease-out',
    }),
  );
};

const bookedTimeWrapper = ({
  isMoreThanHour,
  allMeetingRooms,
  bookingMinutes,
}: {
  isMoreThanHour: boolean;
  allMeetingRooms?: boolean;
  bookingMinutes: number;
}) => {
  let fontSize = ['8px', '8px', '8px', '8px'];
  let lineHeight = ['10px', '10px', '10px', '10px'];

  if (allMeetingRooms) {
    if (bookingMinutes === 15 || bookingMinutes === 20) {
      fontSize = ['3px', '5px', '5px', '5px'];
      lineHeight = ['4px', '6px', '6px', '6px'];
    }

    if (bookingMinutes === 25 || bookingMinutes === 30) {
      fontSize = ['4px', '7px', '7px', '7px'];
      lineHeight = ['5px', '8px', '8px', '8px'];
    }

    if (bookingMinutes > 30) {
      fontSize = ['5px', '7px', '7px', '7px'];
      lineHeight = ['6px', '8px', '8px', '8px'];
    }
  }
  return css(
    mq({
      display: 'flex',
      fontWeight: 600,
      fontSize,
      lineHeight,
    }),
  );
};

const nameTextStyles = ({
  isMoreThanHour,
  allMeetingRooms,
  bookingMinutes,
}: {
  isMoreThanHour: boolean;
  allMeetingRooms?: boolean;
  bookingMinutes: number;
}) => {
  let fontSize = ['8px', '8px', '8px', '8px'];
  let lineHeight = ['10px', '10px', '10px', '10px'];

  if (allMeetingRooms) {
    if (bookingMinutes === 15 || bookingMinutes === 20) {
      fontSize = ['3px', '5px', '5px', '5px'];
      lineHeight = ['4px', '6px', '6px', '6px'];
    }

    if (bookingMinutes === 25 || bookingMinutes === 30) {
      fontSize = ['4px', '7px', '7px', '7px'];
      lineHeight = ['5px', '8px', '8px', '8px'];
    }

    if (bookingMinutes > 30) {
      fontSize = ['5px', '7px', '7px', '7px'];
      lineHeight = ['6px', '8px', '8px', '8px'];
    }
  }
  return css(
    mq({
      display: 'flex',
      fontWeight: 600,
      fontSize,
      lineHeight,
      marginRight: '5px',
    }),
  );
};

const repeatIconsStyles = ({
  colorScheme,
  allMeetingRooms,
  isMoreThanHour,
  bookingMinutes,
}: {
  colorScheme: string;
  allMeetingRooms?: boolean;
  isMoreThanHour: boolean;
  bookingMinutes: number;
}) => {
  let fontSize = ['16px', '16px', '16px', '16px'];
  let bottom = '0px';

  if (isMoreThanHour) {
    bottom = allMeetingRooms ? '1px' : '2px';
  }

  if (allMeetingRooms) {
    if (bookingMinutes === 15 || bookingMinutes === 20) {
      fontSize = ['6px', '12px', '12px', '12px'];
    }

    if (bookingMinutes === 25 || bookingMinutes === 30) {
      fontSize = ['7px', '16px', '16px', '16px'];
    }

    if (bookingMinutes > 30) {
      fontSize = ['8px', '16px', '16px', '16px'];
    }
  }

  return css(
    mq({
      position: isMoreThanHour ? 'relative' : 'static',
      bottom,
      fontSize,
      color: '#FFFFFF',
      margin: ['0px 2px', '0px 5px', '0px 5px', '0px 5px'],
    }),
  );
};

const bookInfoWrapperStyles = ({
  isMoreThanHour,
  isOneHour,
  allMeetingRooms,
}: {
  isMoreThanHour: boolean;
  isOneHour: boolean;
  allMeetingRooms?: boolean;
}) => {
  let justifyContent;
  let alignItems;

  if (isMoreThanHour) {
    justifyContent = 'flex-start';
    alignItems = 'flex-start';
  } else {
    justifyContent = 'flex-start';
    alignItems = 'center';
  }

  if (isOneHour) {
    justifyContent = 'center';
    alignItems = 'flex-start';
  }

  if (allMeetingRooms && !isMoreThanHour && !isOneHour) {
    justifyContent = 'center';
    alignItems = 'flex-start';
  }
  return css({
    display: 'flex',
    flexDirection: isMoreThanHour || isOneHour || allMeetingRooms ? 'column' : 'row',
    justifyContent,
    alignItems,
  });
};

const repeatInfoWrapperStyles = ({
  isMoreThanHour,
  isOneHour,
  allMeetingRooms,
  bookingMinutes,
}: {
  isMoreThanHour: boolean;
  isOneHour: boolean;
  allMeetingRooms?: boolean;
  bookingMinutes: number;
}) => {
  let justifyContent;
  let alignItems;
  let paddingTop;
  let height = '100%';
  let marginTop;

  if (isMoreThanHour) {
    justifyContent = 'flex-start';
    alignItems = 'flex-start';
  } else {
    justifyContent = 'center';
    alignItems = 'center';
  }

  if (isOneHour) {
    justifyContent = 'center';
    alignItems = 'center';
    height = '50%';
    marginTop = allMeetingRooms ? '13px' : '12px';
    paddingTop = allMeetingRooms ? '26px' : '22px';
    paddingTop = '0px';
  }

  if (allMeetingRooms && !isMoreThanHour && !isOneHour) {
    justifyContent = 'center';
    alignItems = 'center';
  }
  return css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent,
    alignItems,
    height,
    marginTop,
    paddingTop,
  });
};

const repeatValueTextStyles = ({
  isMoreThanHour,
  allMeetingRooms,
  bookingMinutes,
}: {
  isMoreThanHour: boolean;
  allMeetingRooms?: boolean;
  bookingMinutes: number;
}) => {
  let fontSize = ['8px', '8px', '8px', '8px'];
  let lineHeight = ['10px', '10px', '10px', '10px'];

  if (allMeetingRooms) {
    if (bookingMinutes === 15 || bookingMinutes === 20) {
      fontSize = ['3px', '5px', '5px', '5px'];
      lineHeight = ['4px', '6px', '6px', '6px'];
    }

    if (bookingMinutes === 25 || bookingMinutes === 30) {
      fontSize = ['4px', '7px', '7px', '7px'];
      lineHeight = ['5px', '8px', '8px', '8px'];
    }

    if (bookingMinutes > 30) {
      fontSize = ['5px', '7px', '7px', '7px'];
      lineHeight = ['6px', '8px', '8px', '8px'];
    }
  }
  return css(
    mq({
      display: 'flex',
      fontWeight: 600,
      fontSize,
      lineHeight,
    }),
  );
};

const styles = {
  bookedRowStyles,
  bookedTimeWrapper,
  nameTextStyles,
  repeatIconsStyles,
  bookInfoWrapperStyles,
  repeatInfoWrapperStyles,
  repeatValueTextStyles,
};

export default styles;
