/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useTelegram } from '../../../hooks/useTelegram';
import {
  useBookMeetingRoomMutation,
  useEditMeetingRoomBookingMutation,
  useLazyGetAllMeetingRoomsUsersQuery,
  useRemoveMeetingRoomBookingMutation,
} from '../../../api/rootApi';
import { APP_ROUTES, MIN_BOOKING_MINUTES_VALUE, REPEAT_VALUE } from '../../../constants';
import { ConfirmModal } from './confirmModal';
import StartTimePicker from './startTimePicker';
import EndTimePicker from './endTimePicker';
import BookMeetingRoomHeader from './bookMeetingRoomHeader';
import {
  MeetingRoomUser,
  setAllMeetingRoomUsers,
  setEditedUserMeetingRoom,
} from '../../../store/reducers/meetingRooms';
import { BookInfoModal } from './bookInfoModal';
import { isTimeDisabled } from '../../../utils/isTimeDisabled';
import EditButtons from './editButtons';
import { DeleteInfoModal } from './deleteInfoModal';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';
import RepeatButtons from './repeatButtons';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

const TIME_ERROR_MESSAGE = 'This time is unavailable';
const REPEATED_TIME_ERROR_MESSAGE = 'This time is unavailable for the selected period';

function BookMeetingRoomLayout() {
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null | undefined>();
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null | undefined>();
  const [calendarValue, setCalendarValue] = useState<Dayjs | null | undefined>();
  const [chosenDateState, setChosenDateState] = useState<string | undefined>(undefined);
  const [startTimeError, setStarTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [checked, setChecked] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [userHaveBookingsModalActive, setUserHaveBookingsModalActive] = useState(false);
  const [bookedMeetingRoom, setBookedMeetingRoom] = useState<MeetingRoomUser | null>(null);
  const [allDayBookedMeetingRoom, setAllDayBookedMeetingRoom] = useState<MeetingRoomUser | null>(
    null,
  );
  const [editModeActive, setEditModeActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [checkedRepeatMode, setCheckedRepeatMode] = useState(false);
  const [repeatRadioValue, setRepeatRadioValue] = useState('Never');
  const [repeatEndDate, setRepeatEndDate] = useState<Dayjs | null>(null);
  const [repeatValue, setRepeatValue] = useState<REPEAT_VALUE>(REPEAT_VALUE.DAY);

  const [queryParams] = useSearchParams();

  const editedMeetingRoomBookingId = queryParams.get('editedMeetingRoomBookingId') ?? undefined;
  const chosenStartTime = queryParams.get('bookingStartTime') ?? undefined;
  const chosenEndTime = queryParams.get('bookingEndTime') ?? undefined;
  const meetingRoomId = queryParams.get('meetingRoomId') ?? undefined;
  const bookingDate = queryParams.get('bookingDate') ?? undefined;
  const meetingRoomName = queryParams.get('meetingRoomName') ?? undefined;
  const isMyBookings = Boolean(queryParams.get('isMyBookings'));
  const isAllMeetingRooms = Boolean(queryParams.get('isAllMeetingRooms'));
  const isAllDayChecked = Boolean(queryParams.get('isAllDay'));

  const { scrollNavigate } = useEnhancedNavigate();

  const todayDate = dayjs().format('YYYY-MM-DD');

  const dispatch = useDispatch();

  const { tg, queryId, tgUserId, colorScheme } = useTelegram();

  const editedMeetingRoomBooking = useAppSelector(
    (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
  );

  const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
    useLazyGetAllMeetingRoomsUsersQuery();

  const [bookMeetingRoom] = useBookMeetingRoomMutation();

  const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

  const [editMeetingRoomBooking, { isLoading: isEditMeetingRoomBookingLoading }] =
    useEditMeetingRoomBookingMutation();

  useEffect(() => {
    if (editedMeetingRoomBookingId && editedMeetingRoomBooking) {
      setStartTimeValue(dayjs(`${todayDate} ${chosenStartTime}`));
      setEndTimeValue(dayjs(`${todayDate} ${chosenEndTime}`));
      setCheckedRepeatMode(editedMeetingRoomBooking.userMeetingRoomInfo.isRepeated);
      const radioValue = editedMeetingRoomBooking.userMeetingRoomInfo.isRepeatAlways
        ? 'Never'
        : 'On';
      setRepeatRadioValue(radioValue);
      if (radioValue === 'On') {
        setRepeatEndDate(dayjs(editedMeetingRoomBooking.userMeetingRoomInfo.repeatEndDate));
      }
      if (editedMeetingRoomBooking?.userMeetingRoomInfo.repeatValue) {
        setRepeatValue(editedMeetingRoomBooking.userMeetingRoomInfo.repeatValue);
      }
      if (editedMeetingRoomBooking.userMeetingRoomInfo.repeatEndDate) {
        setRepeatEndDate(dayjs(`${editedMeetingRoomBooking.userMeetingRoomInfo.repeatEndDate}`));
      } else {
        setRepeatEndDate(dayjs());
      }
    } else {
      setStartTimeValue(dayjs(`${todayDate} ${chosenStartTime}`));
      setEndTimeValue(dayjs(`${todayDate} ${chosenEndTime}`));
      setChecked(isAllDayChecked);
    }
  }, []);

  useEffect(() => {
    if (repeatRadioValue === 'Never' && !editedMeetingRoomBooking) {
      setRepeatEndDate(null);
    } else if (!editedMeetingRoomBooking) {
      setRepeatEndDate(dayjs(chosenDateState));
    }
  }, [repeatRadioValue]);

  useEffect(() => {
    if (chosenDateState && meetingRoomId) {
      dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: [] }));

      if (editedMeetingRoomBooking) {
        let editedRepeatEndDate;
        let getRepeatValue;

        if (
          editedMeetingRoomBooking.userMeetingRoomInfo.isRepeatAlways ||
          repeatRadioValue === 'Never'
        ) {
          editedRepeatEndDate = undefined;
        } else if (editedMeetingRoomBooking.userMeetingRoomInfo.isRepeated && checkedRepeatMode) {
          editedRepeatEndDate = dayjs(
            editedMeetingRoomBooking.userMeetingRoomInfo.repeatEndDate,
          ).format('YYYY-MM-DD');
        } else if (!editedMeetingRoomBooking.userMeetingRoomInfo.isRepeated) {
          editedRepeatEndDate = dayjs(editedMeetingRoomBooking.userMeetingRoomInfo.date).format(
            'YYYY-MM-DD',
          );
        }

        if (repeatValue === REPEAT_VALUE.WEEK || repeatValue === REPEAT_VALUE.MONTH) {
          getRepeatValue = repeatValue;
        }

        getAllMeetingRoomUsers({
          date: chosenDateState,
          endDate: editedRepeatEndDate,
          repeatValue: getRepeatValue,
        });
      } else {
        let endDate: string | undefined;
        let getRepeatValue;

        if (
          checkedRepeatMode &&
          repeatEndDate &&
          repeatEndDate.format('YYYY-MM-DD') !== chosenDateState &&
          repeatRadioValue !== 'Never'
        ) {
          endDate = repeatEndDate?.format('YYYY-MM-DD');
        }

        if (checkedRepeatMode && repeatRadioValue === 'Never') {
          const maxDate = dayjs().add(1, 'year').format('YYYY-MM-DD');
          endDate = dayjs(`${maxDate} 00:00:00`).format('YYYY-MM-DD');
        }

        if (repeatValue === REPEAT_VALUE.WEEK || repeatValue === REPEAT_VALUE.MONTH) {
          getRepeatValue = repeatValue;
        }

        getAllMeetingRoomUsers({
          date: chosenDateState,
          endDate,
          repeatValue: getRepeatValue,
        });
      }
    }
  }, [
    chosenDateState,
    checkedRepeatMode,
    repeatEndDate,
    repeatRadioValue,
    repeatValue,
    editModeActive,
  ]);

  const meetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter(
    (m) =>
      m.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id &&
      `MR${meetingRoomName?.split(' ')[2]}` === m.userMeetingRoomInfo.spaceName,
  );

  const allMeetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter((m) => m.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id);

  useEffect(() => {
    if (bookingDate) {
      setCalendarValue(dayjs(bookingDate));
      setChosenDateState(bookingDate);
    }
  }, [bookingDate]);

  useEffect(() => {
    if (startTimeValue && endTimeValue) {
      const myAllBookings = allMeetingRoomUsers.filter((u) => u.telegramUserId === tgUserId);

      const key = 'startTime';

      const uniqueAllBookings = [
        ...new Map(myAllBookings.map((item) => [item.userMeetingRoomInfo[key], item])).values(),
      ];

      const uniqueMeetingRoomBookings = [
        ...new Map(meetingRoomUsers.map((item) => [item.userMeetingRoomInfo[key], item])).values(),
      ];

      const isStartTimeDisabled = isTimeDisabled(
        startTimeValue.format('HH:mm'),
        meetingRoomUsers,
        false,
      );

      const isStartTimeDisabledInAllMeetingRooms = isTimeDisabled(
        startTimeValue.format('HH:mm'),
        myAllBookings,
        false,
      );

      if (isStartTimeDisabled || isStartTimeDisabledInAllMeetingRooms) {
        if (checkedRepeatMode) {
          setStarTimeError(REPEATED_TIME_ERROR_MESSAGE);
        } else {
          setStarTimeError(TIME_ERROR_MESSAGE);
        }
      } else {
        setStarTimeError('');
      }

      const isMoreThan15Minutes =
        endTimeValue.diff(startTimeValue, 'minutes') >= MIN_BOOKING_MINUTES_VALUE;

      if (!isMoreThan15Minutes) {
        setEndTimeError('Booking time should be not less than 15 minutes');
      }

      const isEndTimeDisabled = isTimeDisabled(
        endTimeValue.format('HH:mm'),
        uniqueMeetingRoomBookings,
        true,
        startTimeValue,
      );

      const isEndTimeDisabledInAllMeetingRooms = isTimeDisabled(
        endTimeValue.format('HH:mm'),
        uniqueAllBookings,
        true,
        startTimeValue,
      );

      if ((isEndTimeDisabled || isEndTimeDisabledInAllMeetingRooms) && isMoreThan15Minutes) {
        if (checkedRepeatMode) {
          setEndTimeError(REPEATED_TIME_ERROR_MESSAGE);
        } else {
          setEndTimeError(TIME_ERROR_MESSAGE);
        }
      }

      if (!isEndTimeDisabled && !isEndTimeDisabledInAllMeetingRooms && isMoreThan15Minutes) {
        setEndTimeError('');
      }
    }
  }, [
    startTimeValue,
    endTimeValue,
    chosenDateState,
    meetingRoomId,
    editedMeetingRoomBooking,
    allMeetingRoomUsers,
    meetingRoomUsers,
  ]);

  useEffect(() => {
    const bookedOtherMeetingRoom = allMeetingRoomUsers.find((u) => {
      const bookedStartTimeDate = dayjs(`${todayDate} ${u.userMeetingRoomInfo.startTime}`);
      const bookedEndTimeDate = dayjs(`${todayDate} ${u.userMeetingRoomInfo.endTime}`);

      // @ts-ignore
      const isStartTimeBetween = startTimeValue?.isBetween(
        bookedStartTimeDate,
        bookedEndTimeDate,
        '[]',
      );
      // @ts-ignore
      const isEndTimeBetween = endTimeValue?.isBetween(
        bookedStartTimeDate,
        bookedEndTimeDate,
        '[]',
      );

      const isEndTimeAfterBookedEndTime = endTimeValue?.isAfter(bookedEndTimeDate);

      const isStartTimeBeforeBookedTime = startTimeValue?.isBefore(bookedStartTimeDate);

      if (
        u.userMeetingRoomInfo.roomId !== meetingRoomId &&
        u.telegramUserId === tgUserId &&
        ((startTimeValue && endTimeValue && (isEndTimeBetween || isStartTimeBetween)) ||
          startTimeValue?.format('HH:mm:ss') === u.userMeetingRoomInfo.startTime ||
          endTimeValue?.format('HH:mm:ss') === u.userMeetingRoomInfo.endTime ||
          (startTimeValue &&
            endTimeValue &&
            isStartTimeBeforeBookedTime &&
            isEndTimeAfterBookedEndTime))
      ) {
        return u;
      }
      return undefined;
    });

    if (bookedOtherMeetingRoom && !startTimeError && !endTimeError) {
      setBookedMeetingRoom(bookedOtherMeetingRoom);
      setUserHaveBookingsModalActive(true);
    } else {
      setBookedMeetingRoom(null);
      setUserHaveBookingsModalActive(false);
    }
  }, [
    startTimeValue,
    endTimeValue,
    meetingRoomId,
    chosenDateState,
    allMeetingRoomUsers,
    startTimeError,
    endTimeError,
  ]);

  const onSendData = useCallback(() => {
    if (
      queryId &&
      startTimeValue &&
      endTimeValue &&
      tgUserId &&
      !bookedMeetingRoom &&
      chosenDateState &&
      meetingRoomName &&
      meetingRoomId
    ) {
      const startTimeString = startTimeValue.format('HH:mm:ss');
      const endTimeString = endTimeValue.format('HH:mm:ss');

      const isRepeatAlways = repeatRadioValue === 'Never';

      const data = {
        queryId,
        meetingRoomId,
        telegramUserId: tgUserId,
        date: chosenDateState,
        startTime: startTimeString,
        endTime: endTimeString,
        spaceName: meetingRoomName,
        allDay: checked,
        isRepeated: checkedRepeatMode,
        repeatValue: checkedRepeatMode ? repeatValue : null,
        repeatEndDate: checkedRepeatMode ? repeatEndDate?.format('YYYY-MM-DD') : null,
        isRepeatAlways: checkedRepeatMode ? isRepeatAlways : false,
      };

      bookMeetingRoom(data);
    }
  }, [
    startTimeValue,
    endTimeValue,
    chosenDateState,
    repeatValue,
    checkedRepeatMode,
    repeatEndDate,
    repeatRadioValue,
  ]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Confirm',
    });
  }, []);

  useEffect(() => {
    if (
      !startTimeValue ||
      editedMeetingRoomBooking ||
      !endTimeValue ||
      endTimeError ||
      startTimeError ||
      userHaveBookingsModalActive ||
      bookedMeetingRoom ||
      modalActive ||
      isEditMeetingRoomBookingLoading
    ) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  }, [
    startTimeValue,
    endTimeValue,
    endTimeError,
    startTimeError,
    modalActive,
    userHaveBookingsModalActive,
    bookedMeetingRoom,
    editedMeetingRoomBooking,
    isEditMeetingRoomBookingLoading,
  ]);

  const isAuthorHaveBookings = meetingRoomUsers.some((m) => m.telegramUserId === tgUserId);

  const isOtherUsersHaveBooking = meetingRoomUsers.some((m) => m.telegramUserId !== tgUserId);

  const onModalCancel = () => {
    setModalActive(false);
  };

  const onModalConfirm = () => {
    const minTime = dayjs(`${todayDate} 07:00:00`);
    const maxTime = dayjs(`${todayDate} 23:00:00`);

    setStartTimeValue(minTime);
    setEndTimeValue(maxTime);

    setModalActive(false);
  };

  const chosenDateString = dayjs(chosenDateState).format('DD.MM.YYYY');

  const onCloseDeleteInfoModalHandler = () => {
    if (editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeated) {
      setDeleteModalActive(false);
    } else {
      dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

      setDeleteModalActive(false);

      if (isAllMeetingRooms) {
        scrollNavigate({
          top: 0,
          left: 0,
          path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
          replace: true,
          behavior: 'smooth',
        });
      } else {
        scrollNavigate({
          top: 0,
          left: 0,
          path: `${
            APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE
          }?date=${chosenDateState}&meetingRoomId=${meetingRoomId}&meetingRoomName=${meetingRoomName}${
            isMyBookings ? `&isMyBookings=${isMyBookings}` : ''
          }`,
          replace: true,
          behavior: 'smooth',
        });
      }
    }
  };

  const onEditHandler = async () => {
    if (startTimeValue && endTimeValue && editedMeetingRoomBooking) {
      const isRepeatAlways = repeatRadioValue === 'Never';

      const repeatEndDateValue = isRepeatAlways ? null : repeatEndDate?.format('YYYY-MM-DD');

      const result = await editMeetingRoomBooking({
        id: editedMeetingRoomBooking.userMeetingRoomInfo.id,
        startTime: startTimeValue.format('HH:mm:ss'),
        endTime: endTimeValue.format('HH:mm:ss'),
        allDay: checked,
        isRepeated: checkedRepeatMode,
        repeatValue: checkedRepeatMode ? repeatValue : null,
        repeatEndDate: checkedRepeatMode ? repeatEndDateValue : null,
        isRepeatAlways: checkedRepeatMode ? isRepeatAlways : false,
      }).unwrap();

      if (result) {
        dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

        setEditModeActive(false);

        if (isAllMeetingRooms) {
          scrollNavigate({
            top: 0,
            left: 0,
            path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
            replace: true,
            behavior: 'smooth',
          });
        } else {
          scrollNavigate({
            top: 0,
            left: 0,
            path: `${
              APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE
            }?date=${chosenDateState}&meetingRoomId=${meetingRoomId}&meetingRoomName=${meetingRoomName}${
              isMyBookings ? `&isMyBookings=${isMyBookings}` : ''
            }`,
            replace: true,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  const deleteRepeatedBooking = async () => {
    if (editedMeetingRoomBooking) {
      const result = await removeMeetingRoomBooking({
        id: editedMeetingRoomBooking.userMeetingRoomInfo.id,
      }).unwrap();

      if (result) {
        dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));

        setDeleteModalActive(false);

        if (isAllMeetingRooms) {
          scrollNavigate({
            top: 0,
            left: 0,
            path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
            replace: true,
            behavior: 'smooth',
          });
        } else {
          scrollNavigate({
            top: 0,
            left: 0,
            path: `${
              APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE
            }?date=${bookingDate}&meetingRoomId=${meetingRoomId}&meetingRoomName=${meetingRoomName}${
              isMyBookings ? `&isMyBookings=${isMyBookings}` : ''
            }`,
            replace: true,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  return (
    <div css={styles.wrapperStyles({ colorScheme })}>
      {isFetchingGetAllMeetingRoomUsers ? (
        <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <BookMeetingRoomHeader
            setModalActive={setModalActive}
            setStartTimeValue={setStartTimeValue}
            setEndTimeValue={setEndTimeValue}
            isMyBookings={isMyBookings}
            setChecked={setChecked}
            checked={checked}
            chosenDateState={chosenDateState}
            isOtherUsersHaveBooking={isOtherUsersHaveBooking}
            isAuthorHaveBookings={isAuthorHaveBookings}
            setChosenDateState={setChosenDateState}
            calendarValue={calendarValue}
            setCalendarValue={setCalendarValue}
            allDayBookedMeetingRoom={allDayBookedMeetingRoom}
            setAllDayBookedMeetingRoom={setAllDayBookedMeetingRoom}
            editModeActive={editModeActive}
            meetingRoomName={meetingRoomName}
            meetingRoomId={meetingRoomId}
            bookingDate={bookingDate}
            isAllMeetingRooms={isAllMeetingRooms}
            chosenStartTime={chosenStartTime}
            chosenEndTime={chosenEndTime}
          />
          <h3 css={styles.titleStyles({ colorScheme })}>Set meeting room time</h3>
          <StartTimePicker
            startTimeValue={startTimeValue}
            setStartTimeValue={setStartTimeValue}
            chosenDateState={chosenDateState}
            endTimeValue={endTimeValue}
            setError={setStarTimeError}
            startTimeError={startTimeError}
            editModeActive={editModeActive}
            checked={checked}
            setChecked={setChecked}
            meetingRoomUsers={meetingRoomUsers}
          />
          <EndTimePicker
            startTimeValue={startTimeValue}
            endTimeValue={endTimeValue}
            setEndTimeValue={setEndTimeValue}
            chosenDateState={chosenDateState}
            setError={setEndTimeError}
            endTimeError={endTimeError}
            editModeActive={editModeActive}
            checked={checked}
            setChecked={setChecked}
            meetingRoomUsers={meetingRoomUsers}
          />
          <RepeatButtons
            editModeActive={editModeActive}
            checkedRepeatMode={checkedRepeatMode}
            setCheckedRepeatMode={setCheckedRepeatMode}
            repeatRadioValue={repeatRadioValue}
            setRepeatRadioValue={setRepeatRadioValue}
            repeatEndDate={repeatEndDate}
            setRepeatEndDate={setRepeatEndDate}
            repeatValue={repeatValue}
            setRepeatValue={setRepeatValue}
            chosenDateState={chosenDateState}
          />
          {editedMeetingRoomBooking && (
            <EditButtons
              isRepeated={editedMeetingRoomBooking.userMeetingRoomInfo.isRepeated}
              isEditMeetingRoomBookingLoading={isEditMeetingRoomBookingLoading}
              onEditHandler={onEditHandler}
              startTimeValue={startTimeValue}
              endTimeValue={endTimeValue}
              setEditModeActive={setEditModeActive}
              setDeleteModalActive={setDeleteModalActive}
              editModeActive={editModeActive}
              startTimeError={startTimeError}
              endTimeError={endTimeError}
            />
          )}
          <ConfirmModal
            onCancel={onModalCancel}
            open={modalActive}
            setOpen={setModalActive}
            onConfirm={onModalConfirm}
          />
          <DeleteInfoModal
            open={deleteModalActive}
            onClose={onCloseDeleteInfoModalHandler}
            isRepeated={!!editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeated}
            onDeleteWorkPlaceHandler={deleteRepeatedBooking}
          />
          {userHaveBookingsModalActive && bookedMeetingRoom && meetingRoomName && (
            <BookInfoModal
              setOpen={setUserHaveBookingsModalActive}
              open={userHaveBookingsModalActive}
              meetingRoomName={meetingRoomName}
              bookedMeetingRoomName={bookedMeetingRoom.userMeetingRoomInfo.spaceName}
              chosenDate={chosenDateString}
              setChecked={setChecked}
            />
          )}
        </>
      )}
    </div>
  );
}

export default BookMeetingRoomLayout;
