import { Route, Routes } from 'react-router-dom';
/* import AllMeetingRoomsSchedule from '../components/layouts/allMeetingRoomsSchedule'; */
import BookMeetingRoomLayout from '../components/layouts/bookMeetingRoomLayout';
import BookPeriodLayout from '../components/layouts/bookPeriodLayout';
import MeetingRoomTimetable from '../components/layouts/meetingRoomTimetable';
import MyBookingsLayout from '../components/layouts/myBookingsLayout';
import OfficeMap from '../components/layouts/officeMapLayout';
import RegistrationLayout from '../components/layouts/registrationLayout';
import UserProfile from '../components/layouts/userProfile';
import { APP_ROUTES } from '../constants';

export function Router() {
  return (
    <Routes>
      <Route path={APP_ROUTES.HOME_PAGE} element={<OfficeMap />} />
      <Route path={APP_ROUTES.REGISTRATION_PAGE} element={<RegistrationLayout />} />
      <Route path={APP_ROUTES.USER_PROFILE_PAGE} element={<UserProfile />} />
      <Route path={APP_ROUTES.BOOK_PERIOD_PAGE} element={<BookPeriodLayout />} />
      <Route path={APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE} element={<MeetingRoomTimetable />} />
      <Route path={APP_ROUTES.BOOK_MEETING_ROOM} element={<BookMeetingRoomLayout />} />
      <Route path={APP_ROUTES.MY_BOOKINGS_ROUTE} element={<MyBookingsLayout />} />
      {/*  <Route
        path={APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}
        element={<AllMeetingRoomsSchedule />}
      /> */}
    </Routes>
  );
}
